import React, { useState } from "react";
import { Form, Formik } from "formik";
import { createRegion, getAllRegions, getLoadingRegions, getRegionData } from "features/v1/regions";
import { useDispatch, useSelector } from "react-redux";
// import { Button } from "reactstrap";
import OverlayLoader from "components/v1/Loader/OverlayLoader";
// import FormGridRow from "components/v1/Forms/FormGridRow";
// import FormGridTitle from "components/v1/Forms/FormGridTitle";
// import CustomFormField from "components/v1/Forms/CustomFormField";
import { getUserData } from "features/v1/auth";
// import CustomSelect from "components/v1/CustomSelect/CustomSelect";
import _ from "lodash";
import { regionValidator } from "validators/regionValidator";
import { FormInput } from "components/inputs";
import { Box, Button, Text } from "organic";
import { getRegionTerm } from "utils/flavour-utils";

const regionTerm = getRegionTerm();

function CreateRegionForm({ modal }) {
  const dispatch = useDispatch();

  const regionData = useSelector(getRegionData);
  const loadingRegions = useSelector(getLoadingRegions);
  const loadUserData = useSelector(getUserData);

  const [submitted, setSubmitted] = useState(false);

  const regionId = React.useMemo(() => {
    let regionNumbers = regionData.map(region => region.value.replace(/[a-z]/gi, ""));
    console.log({ regionNumbers });
    if (regionNumbers.length > 0) {
      return `r${Number(_.max(regionNumbers)) + 1}`;
    }
    return "r1";
  }, [regionData]);

  const initRegionValues = {
    value: regionId,
    label: "",
    zones: [],
  };

  // const incrementID = prevID => {
  //   const newRegionIDValue = `r${Number(prevID.slice(1)) + 1}`;
  //   return newRegionIDValue;
  // };

  React.useLayoutEffect(() => {
    dispatch(getAllRegions());
  }, []);

  if (loadingRegions && !submitted) {
    return <OverlayLoader active height={400} />;
  }
  return (
    <div>
      <Formik
        initialValues={initRegionValues}
        validationSchema={regionValidator}
        onSubmit={(values, formOptions) => {
          setSubmitted(true);
          values.organization = loadUserData.organization;
          // FIXME use useRole to restrict access
          dispatch(createRegion(values, { formOptions, modal }));
        }}
      >
        {({ isSubmitting, errors }) => (
          <Box>
            <OverlayLoader active={isSubmitting}>
              <Form>
                <Text mx={2} color="red" textAlign="center">
                  {errors.message}
                </Text>

                <Box my={40}>
                  <Box mb={4}>
                    <FormInput mb={4} placeholder="Name...eg Region 1" name="label" label="Region Name" required />
                  </Box>

                  <Box mb={4}>
                    <FormInput
                      mb={4}
                      placeholder="autogenrated region serial ID"
                      disabled
                      // value={regionId}
                      name="value"
                      // onChange={val => {
                      //   // handleChange(val);
                      //   setRegionId(val);
                      // }}
                      label={`${regionTerm} ID`}
                      required
                    />
                  </Box>
                  {/* <Box mb={4}>
                    <Select
                      label="Zones"
                      placeholder="Select Zones for this region"
                      isMulti
                      options={zoneOptions}
                      name="zones"
                      onChange={_zones =>
                        setFieldValue(
                          "zones",
                          _zones.map(zone => zone._id)
                        )
                      }
                    />
                  </Box> */}
                </Box>
                <Button
                  colorScheme="primary"
                  display="block"
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 1}
                >
                  Create new {regionTerm}
                </Button>
              </Form>
            </OverlayLoader>
          </Box>
        )}
      </Formik>
    </div>
  );
}

export default CreateRegionForm;
