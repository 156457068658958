import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import Onboarding_API from "services/onboardingApiService";

export function useGetContractualFarmerLogin({ params }) {
  const fetcher = async () => {
    const response = Onboarding_API.fetchContractualFarmerPassword(params);
    return response?.data || {};
  };
  // get contractual farmer password
  return useQuery({
    queryKey: [rqKeys.onboarding.fetchContractualFarmerPass, ...Object.values(params)],
    queryFn: fetcher,
  });
}
