const env = process.env.REACT_APP_PREFERRED_API; // local (deprecated use staging) | staging | production | v2

const defaultAdminApi = process.env.REACT_APP_ADMIN; // Required fallback if all other values are absent
const defaultMainApi = process.env.REACT_APP_USERS; // Required fallback if all other values are absent
const defaultV2Api = process.env.REACT_APP_API_BASE_URL;

const liveAdminApi = process.env.REACT_APP_LIVE_ADMIN;
const stagingAdminApi = process.env.REACT_APP_STAGING_ADMIN;
const localAdminApi = stagingAdminApi;

const liveUsersApi = process.env.REACT_APP_LIVE_USERS;
const stagingUsersApi = process.env.REACT_APP_STAGING_USERS;
const localUsersApi = stagingUsersApi;

const v2Api = process.env.REACT_APP_API_BASE_URL;

const openWeatherForcastApi = `${process.env.REACT_APP_OPEN_WEATHER_FORECAST}`;

const ADMIN_BACKEND_API_ID = "admin";

const MAIN_BACKEND_API_ID = "main";

const V2_API_ID = "v2";

const OPEN_WEATHER_FORECAST_API_ID = "openweatherforcast";

const ENDPOINT_NAMES = {
  activationModules: "activationModules",
  admin: "admin",
  agent: "agent",
  auth: "auth",
  bulkSms: "bulkSms",
  clearFarmer: "clearFarmer",
  commitmentFee: "commitmentFee",
  contractors: "contractors",
  crops: "crops",
  equityContribution: "equityContribution",
  farmActivity: "farmActivity",
  farmerClearance: "farmerClearance",
  farmerPayment: "farmerPayment",
  farmers: "farmers",
  farmerSeason: "farmerSeason",
  farmMapping: "farmMapping",
  farmMonitoring: "farmMonitoring",
  farmSupervision: "farmSupervision",
  farmSupervisionCategory: "farmSupervisionCategory",
  harvest: "harvest",
  inputDistribution: "inputDistribution",
  inputs: "inputs",
  language: "language",
  marketplace: "marketplace",
  organization: "organization",
  questionnaire: "questionnaire",
  region: "region",
  riskAgent: "riskAgent",
  riskManagement: "riskManagement",
  role: "role",
  season: "season",
  subscription: "subscription",
  survey: "survey",
  warehouse: "warehouse",
  zone: "zone",
};

const ENDPOINTS = {
  [ENDPOINT_NAMES.activationModules]: "activationModules",
  [ENDPOINT_NAMES.admin]: "admin",
  [ENDPOINT_NAMES.agent]: "agent",
  [ENDPOINT_NAMES.auth]: "admin",
  [ENDPOINT_NAMES.bulkSms]: "sms",
  [ENDPOINT_NAMES.clearFarmer]: "clearFarmer",
  [ENDPOINT_NAMES.commitmentFee]: "farmers/commitmentFee",
  [ENDPOINT_NAMES.contractors]: "admin/contractors",
  [ENDPOINT_NAMES.crops]: "crops",
  [ENDPOINT_NAMES.equityContribution]: "equityContribution",
  [ENDPOINT_NAMES.farmActivity]: "subscription",
  [ENDPOINT_NAMES.farmerClearance]: "clearFarmer",
  [ENDPOINT_NAMES.farmerPayment]: "farmerPayment",
  [ENDPOINT_NAMES.farmers]: "farmers",
  [ENDPOINT_NAMES.farmerSeason]: "farmerSeason",
  [ENDPOINT_NAMES.farmMapping]: "farmers/farmMapping",
  [ENDPOINT_NAMES.farmMonitoring]: "farmSupervision",
  [ENDPOINT_NAMES.farmSupervision]: "farmSupervision",
  [ENDPOINT_NAMES.farmSupervisionCategory]: "farmers/farmSupervisionCategory",
  [ENDPOINT_NAMES.harvest]: "harvest",
  [ENDPOINT_NAMES.inputDistribution]: "admin/inputDistribution",
  [ENDPOINT_NAMES.inputs]: "crops/inputs",
  [ENDPOINT_NAMES.language]: "language",
  [ENDPOINT_NAMES.marketplace]: "marketplace",
  [ENDPOINT_NAMES.organization]: "organization",
  [ENDPOINT_NAMES.questionnaire]: "questionnaire",
  [ENDPOINT_NAMES.region]: "region",
  [ENDPOINT_NAMES.riskAgent]: "riskAgent",
  [ENDPOINT_NAMES.riskManagement]: "survey",
  [ENDPOINT_NAMES.role]: "user/role",
  [ENDPOINT_NAMES.season]: "season",
  [ENDPOINT_NAMES.subscription]: "subscription",
  [ENDPOINT_NAMES.survey]: "survey",
  [ENDPOINT_NAMES.warehouse]: "admin/warehouse",
  [ENDPOINT_NAMES.zone]: "zone",
};

const V2_ENDPOINTS = {
  [ENDPOINT_NAMES.activationModules]: "activationModules",
  [ENDPOINT_NAMES.admin]: "admin",
  [ENDPOINT_NAMES.agent]: "agent",
  [ENDPOINT_NAMES.auth]: "admin",
  [ENDPOINT_NAMES.bulkSms]: "resource/sms",
  [ENDPOINT_NAMES.clearFarmer]: "resource/clearFarmer",
  [ENDPOINT_NAMES.commitmentFee]: "resource/farmers/commitmentFee",
  [ENDPOINT_NAMES.contractors]: "admin/contractors",
  [ENDPOINT_NAMES.crops]: "resource/crops",
  [ENDPOINT_NAMES.equityContribution]: "equityContribution",
  [ENDPOINT_NAMES.farmActivity]: "resource/subscription",
  [ENDPOINT_NAMES.farmerClearance]: "resource/clearFarmer",
  [ENDPOINT_NAMES.farmerPayment]: "resource/farmerPayment",
  [ENDPOINT_NAMES.farmers]: "resource/farmers",
  [ENDPOINT_NAMES.farmerSeason]: "resource/farmerSeason",
  [ENDPOINT_NAMES.farmMapping]: "farmers/farmMapping",
  [ENDPOINT_NAMES.farmMonitoring]: "resource/farmSupervision",
  [ENDPOINT_NAMES.farmSupervision]: "resource/farmSupervision",
  [ENDPOINT_NAMES.farmSupervisionCategory]: "resource/farmers/farmSupervisionCategory",
  [ENDPOINT_NAMES.harvest]: "resource/harvest",
  [ENDPOINT_NAMES.inputDistribution]: "admin/inputDistribution",
  [ENDPOINT_NAMES.inputs]: "resource/crops/inputs",
  [ENDPOINT_NAMES.language]: "resource/language",
  [ENDPOINT_NAMES.marketplace]: "marketplace",
  [ENDPOINT_NAMES.organization]: "organization",
  [ENDPOINT_NAMES.questionnaire]: "questionnaire",
  [ENDPOINT_NAMES.region]: "resource/region",
  [ENDPOINT_NAMES.riskAgent]: "resource/riskAgent",
  [ENDPOINT_NAMES.riskManagement]: "resource/survey",
  [ENDPOINT_NAMES.role]: "user/role",
  [ENDPOINT_NAMES.season]: "resource/season",
  [ENDPOINT_NAMES.subscription]: "resource/subscription",
  [ENDPOINT_NAMES.survey]: "resource/survey",
  [ENDPOINT_NAMES.warehouse]: "admin/warehouse",
  [ENDPOINT_NAMES.zone]: "resource/zone",
};

export const apiConstants = {
  currentPrefferedBackendServer: env,
  apiNames: {
    ADMIN_API: ADMIN_BACKEND_API_ID,
    MAIN_API: MAIN_BACKEND_API_ID,
    WEATHER_FORECAST_API: OPEN_WEATHER_FORECAST_API_ID,
    V2_API: V2_API_ID,
  },
  baseUrls: {
    local: {
      [ADMIN_BACKEND_API_ID]: localAdminApi,
      [MAIN_BACKEND_API_ID]: localUsersApi,
      [V2_API_ID]: v2Api,
      [OPEN_WEATHER_FORECAST_API_ID]: openWeatherForcastApi,
    },
    staging: {
      [ADMIN_BACKEND_API_ID]: stagingAdminApi,
      [MAIN_BACKEND_API_ID]: stagingUsersApi,
      [V2_API_ID]: v2Api,
      [OPEN_WEATHER_FORECAST_API_ID]: openWeatherForcastApi,
    },
    production: {
      [ADMIN_BACKEND_API_ID]: liveAdminApi,
      [MAIN_BACKEND_API_ID]: liveUsersApi,
      [V2_API_ID]: v2Api,
      [OPEN_WEATHER_FORECAST_API_ID]: openWeatherForcastApi,
    },
    v2: {
      [ADMIN_BACKEND_API_ID]: v2Api,
      [MAIN_BACKEND_API_ID]: v2Api,
      [V2_API_ID]: v2Api,
      [OPEN_WEATHER_FORECAST_API_ID]: openWeatherForcastApi,
    },
  },
  versions: ["", "api/v1/"],
  endpointsByBackendServer: {
    [ADMIN_BACKEND_API_ID]: [
      ENDPOINT_NAMES.admin,
      ENDPOINT_NAMES.agent,
      ENDPOINT_NAMES.warehouse,
      ENDPOINT_NAMES.role,
      ENDPOINT_NAMES.questionnaire,
      ENDPOINT_NAMES.organization,
      ENDPOINT_NAMES.contractors,
    ],
    [MAIN_BACKEND_API_ID]: [
      ENDPOINT_NAMES.marketplace,
      ENDPOINT_NAMES.bulkSms,
      ENDPOINT_NAMES.survey,
      ENDPOINT_NAMES.subscription,
      ENDPOINT_NAMES.season,
      ENDPOINT_NAMES.language,
      ENDPOINT_NAMES.inputs,
      ENDPOINT_NAMES.harvest,
      ENDPOINT_NAMES.farmMonitoring,
      ENDPOINT_NAMES.farmSupervision,
      ENDPOINT_NAMES.farmSupervisionCategory,
      ENDPOINT_NAMES.farmerSeason,
      ENDPOINT_NAMES.farmers,
      ENDPOINT_NAMES.farmerPayment,
      ENDPOINT_NAMES.farmActivity,
      ENDPOINT_NAMES.crops,
      ENDPOINT_NAMES.clearFarmer,
      ENDPOINT_NAMES.region,
      ENDPOINT_NAMES.commitmentFee,
      ENDPOINT_NAMES.activationModules,
      ENDPOINT_NAMES.zone,
      ENDPOINT_NAMES.riskAgent,
      ENDPOINT_NAMES.riskManagement,
    ],
    [V2_API_ID]: Object.values(V2_ENDPOINTS), // all endpoints from one main server
  },
  endpoints: env === "v2" ? V2_ENDPOINTS : ENDPOINTS,
  endpointPaths: env === "v2" ? V2_ENDPOINTS : ENDPOINTS,
  endpointNames: ENDPOINT_NAMES,
  defaults: {
    ADMIN_API: defaultAdminApi,
    MAIN_API: defaultMainApi,
    v2_API: defaultV2Api,
    WEATHER_FORCAST_API: openWeatherForcastApi,
  },
};
