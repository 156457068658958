import { ErrorState } from "components/appState";
import L from "leaflet";
import React from "react";
import { Marker, Popup } from "react-leaflet";

const customIcon = L.icon({
  iconUrl: "/farm-marker.svg",
  iconSize: [38, 95],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
  // shadowUrl: "/farm-marker-shadow.svg",
  // shadowSize: [20, 90],
  // shadowAnchor: [22, 94],
});

export const LeafletMarker = ({ position, children, info }) => {
  return (
    <ErrorState useTooltip>
      {position === null ? null : (
        <Marker position={position} icon={customIcon}>
          {children}
          <Popup>{info || null}</Popup>
        </Marker>
      )}
    </ErrorState>
  );
};

LeafletMarker.defaultProps = {
  position: null,
};
