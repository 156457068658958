import { getAOSFlavour } from "services/customerService";

export const getRegionTerm = flavour => {
  let _flavour = flavour;

  if (!_flavour) {
    _flavour = getAOSFlavour();
  }

  const RegionTermsByFlavour = {
    aos: "Region",
    fida: "Section",
    local: "Section", // NOTE: HI DEV CHANGE ME IF YOU LIKE
  };
  return RegionTermsByFlavour?.[`${_flavour}`] ? RegionTermsByFlavour[`${_flavour}`] : "Region";
};
