import {
  addDays,
  addMonths,
  addQuarters,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  isDate as isDateFunction,
  isEqual,
  isSameDay as isSameDayFunction,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { isString } from "utils/shared-utils/text-utils";

function formatDate(date, formatString = "MMM do, yyyy") {
  if (!date) {
    return "";
  }

  if (isString(date)) {
    return format(parseISO(date), formatString);
  }
  return format(date, formatString);
}

function isSameDate(leftDate, rightDate) {
  return isEqual(leftDate, rightDate);
}

function isValidDate(date) {
  return isDateFunction(date);
}

const dateConstants = {
  namedDates: {
    START_OF_TODAY: startOfDay(new Date()),
    END_OF_TODAY: endOfDay(addDays(new Date(), 1)), // actually tomorrow
    START_OF_YESTERDAY: startOfDay(addDays(new Date(), -1)),
    END_OF_YESTERDAY: endOfDay(addDays(new Date(), -1)),
    START_OF_WEEK: startOfWeek(new Date()),
    END_OF_WEEK: endOfWeek(new Date()),
    START_OF_LAST_WEEK: startOfWeek(addDays(new Date(), -7)),
    END_OF_LAST_WEEK: endOfWeek(addDays(new Date(), -7)),
    START_OF_MONTH: startOfMonth(new Date()),
    END_OF_MONTH: endOfMonth(new Date()),
    START_OF_LAST_MONTH: startOfMonth(addMonths(new Date(), -1)),
    END_OF_LAST_MONTH: endOfMonth(addMonths(new Date(), -1)),
    START_OF_LAST_3_MONTHS: startOfMonth(addMonths(new Date(), -3)),
    END_OF_LAST_3_MONTHS: endOfMonth(new Date()),
    START_OF_LAST_12_MONTHS: startOfMonth(addMonths(new Date(), -12)),
    END_OF_LAST_12_MONTHS: endOfMonth(new Date()),
    START_OF_THIS_QUARTER: startOfQuarter(new Date()),
    END_OF_THIS_QUARTER: endOfQuarter(new Date()),
    START_OF_LAST_QUARTER: startOfQuarter(addQuarters(new Date(), -1)),
    END_OF_LAST_QUARTER: endOfQuarter(addQuarters(new Date(), -1)),
    START_OF_MONTH_TO_DATE: startOfMonth(new Date()),
    END_OF_MONTH_TO_DATE: endOfDay(new Date()),
    START_OF_QUARTER_TO_DATE: startOfQuarter(new Date()),
    END_OF_QUARTER_TO_DATE: endOfDay(new Date()),
    START_OF_YEAR_TO_DATE: startOfYear(new Date()),
    END_OF_YEAR_TO_DATE: endOfDay(new Date()),
  },
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDayFunction(range.startDate, definedRange.startDate) &&
      isSameDayFunction(range.endDate, definedRange.endDate)
    );
  },
};

function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export { formatDate, isSameDate, createStaticRanges, dateConstants, isValidDate };
