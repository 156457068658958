import React, { useMemo, useState } from "react";
import { addNewQuestionnaire, getLoadingQuestionnaire, getQuestionnaireData } from "features/v1/questionnaire";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  FARMER_ONBOARDING_QUESTIONNAIRE,
  PSYCHOMETRIC_TEST_QUESTIONNAIRE,
  SURVEY_QUESTIONNAIRE,
  checkForQuestionnaireType,
  initialCreateQuestionnaireValues,
  isBvnSurvey,
  surveyTypeOptions,
  surveyTypes,
} from "utils/questionnaire-utils/questionnaire-utils";

import _ from "lodash";
import { getSeasonOptions } from "features/v1/season";

import { Box, Button, Flex } from "organic";
import { FormInput } from "components/inputs";
import defaultBvnSurveyQuestions from "utils/questionnaire-utils/defaultBvnSurveyQuestions";
import defaultFarmerOnboardingQuestions, {
  transformDefaultQuestionValues,
} from "utils/questionnaire-utils/defaultFarmerOnboardingQuestions";
import {
  CropSelect,
  QuestionnaireSelect,
  QuestionnaireModelSelect,
  Select,
  CropCategorySelect,
  CountrySelect,
} from "components/select";
import { removeObjProps } from "utils/v1/sanitize";
import { createQuestionnaireValidationSchema } from "validators/questionnaireValidationSchemas";
import { VisibilityWrapper } from "components/layout";
import { getOrg } from "services/v1/authService";

function CreateQuestionnaireForm({ modal }) {
  const [selectedModel, __setSelectedModel] = useState("");
  const [__selectedQuestionnaireType, setSelectedQuestionnaireType] = useState(null);
  const [importedQuestions, setImportedQuestions] = useState([]);
  const [country, setCountry] = useState();

  let loading = useSelector(getLoadingQuestionnaire);
  let seasonOptions = useSelector(getSeasonOptions);
  let allQuestionnaires = useSelector(getQuestionnaireData);
  const existingFarmerOnboardingQuestionnaireSeasons = allQuestionnaires
    .filter(questionnaireItem => {
      return questionnaireItem.model === FARMER_ONBOARDING_QUESTIONNAIRE;
    })
    .map(farmerOnboardingQuestionnaireItem => farmerOnboardingQuestionnaireItem.season);

  const dispatch = useDispatch();

  const filterSeasonOptions = (option, __inputValue) => {
    if (selectedModel === FARMER_ONBOARDING_QUESTIONNAIRE) {
      return !existingFarmerOnboardingQuestionnaireSeasons.includes(option.value);
    }
  };

  const organization = getOrg();

  const questionnaireTypeOptions = useMemo(() => {
    switch (selectedModel) {
      case SURVEY_QUESTIONNAIRE:
        return surveyTypeOptions;
      default:
        return [];
    }
  }, [selectedModel]);

  const handleQuestionnaireTypeSelectChange = (_selectedQuestionnaireType, { setValues, values }) => {
    setSelectedQuestionnaireType(questionnaireTypeOptions.find(option => option.value === _selectedQuestionnaireType));
    console.log({ _selectedQuestionnaireType });
    switch (_selectedQuestionnaireType) {
      case surveyTypes.BVN_SURVEY: {
        setValues({
          ...values,
          category_crop_type: surveyTypes.BVN_SURVEY,
          questionnaire_type: surveyTypes.BVN_SURVEY,
        });
        break;
      }
      case surveyTypes.WAREHOUSE_SURVEY: {
        setValues({
          ...values,
          category_crop_type: surveyTypes.WAREHOUSE_SURVEY,
          questionnaire_type: surveyTypes.WAREHOUSE_SURVEY,
        });
        break;
      }
      case surveyTypes.MARKETPLACE_SURVEY: {
        setValues({
          ...values,
          category_crop_type: surveyTypes.MARKETPLACE_SURVEY,
          questionnaire_type: surveyTypes.MARKETPLACE_SURVEY,
        });
        break;
      }
      case surveyTypes.RISK_SURVEY: {
        setValues({
          ...values,
          category_crop_type: surveyTypes.RISK_SURVEY,
          questionnaire_type: surveyTypes.RISK_SURVEY,
        });
        break;
      }
      default: {
        setValues({
          ...values,
          farm_operation_category: [],
          category_type: [],
          category_crop_type: "",
          questionnaire_type: selectedModel,
        });
      }
    }
  };

  const handleQuestionnaireModelSelect = (val, { setFieldValue }) => {
    // Reset selected survey type
    setSelectedQuestionnaireType(null);

    // clear previously imported questions
    setImportedQuestions([]);

    setFieldValue("model", val);
    setFieldValue("questionnaire_type", val);
    // setSelectedModel(val);
    if (val !== PSYCHOMETRIC_TEST_QUESTIONNAIRE) {
      setFieldValue("cut_off_mark", 0);
      setFieldValue("score_per_question", 0);
      setFieldValue("psychometrics_status", false);
    }
    if (val === PSYCHOMETRIC_TEST_QUESTIONNAIRE) {
      setFieldValue("psychometrics_status", true);
    }
    if (val === SURVEY_QUESTIONNAIRE) {
      setFieldValue("farm_operation_category", []);
      setFieldValue("category_type", []);
      setFieldValue("category_crop_type", "");
    }
    __setSelectedModel(val);
  };

  const handleFarmOperationCategory = (value, { setValues, values }) => {
    let _farmOperationValues = [];
    if (!value) {
      setValues({
        ...values,
        farm_operation_category: _farmOperationValues,
        category_type: [],
        category_crop_type: "",
      });
    }
    if (Array.isArray(value)) {
      _farmOperationValues = value;
      setValues({
        ...values,
        farm_operation_category: _farmOperationValues,
        category_type: [],
        category_crop_type: "",
      });
    }
    if (_.isString(value)) {
      _farmOperationValues = [value];
      setValues({
        ...values,
        farm_operation_category: _farmOperationValues,
        category_type: [],
        category_crop_type: "",
      });
    }
  };

  const handleCategoryType = (value, { setValues, values }) => {
    let _categoryTypeValues = [];
    if (!value) {
      setValues({
        ...values,
        category_type: _categoryTypeValues,
        category_crop_type: "",
      });
      return;
    }
    if (Array.isArray(value)) {
      _categoryTypeValues = value;
      setValues({
        ...values,
        category_type: _categoryTypeValues,
        category_crop_type: _categoryTypeValues[0],
      });
      return;
    }

    if (_.isString(value)) {
      _categoryTypeValues = [value];
      setValues({
        ...values,
        category_type: _categoryTypeValues,
        category_crop_type: value,
      });
    }
  };

  const handleImportQuestions = selectedQuestionnaire => {
    let importedQuestions = selectedQuestionnaire.questions;

    if (selectedQuestionnaire.model === FARMER_ONBOARDING_QUESTIONNAIRE) {
      importedQuestions = selectedQuestionnaire.questions.slice(11);
    }
    if (isBvnSurvey(selectedQuestionnaire)) {
      importedQuestions = [];
    }
    importedQuestions = importedQuestions.map(question => {
      removeObjProps(question, ["_id", "1000", "", "default", "object Object"]);
      return question;
    });
    console.log({ importedQuestions });
    setImportedQuestions(importedQuestions);
  };

  const handleCreateQuestionnaire = (values, formOptions) => {
    console.log("Submitting form with values:", values, country);

    let _questions = [];
    if (values.model === FARMER_ONBOARDING_QUESTIONNAIRE && country === "Ghana") {
      _questions = transformDefaultQuestionValues({ key: "question_title", value: "State", replace: "State/Province" });
      _questions = transformDefaultQuestionValues({ key: "question_title", value: "LGA", replace: "LGA/District" });
    } else if (values.model === FARMER_ONBOARDING_QUESTIONNAIRE) {
      _questions = defaultFarmerOnboardingQuestions;
    }

    if (!values?.organization) {
      values.organization = getOrg();
    }

    if (checkForQuestionnaireType({ questionnaire: values, type: surveyTypes.RISK_SURVEY })) {
      values.category_crop_type = `${values.category_crop_type}_${values.season}`;
    }
    if (isBvnSurvey(values)) {
      _questions = defaultBvnSurveyQuestions;
    }
    dispatch(
      addNewQuestionnaire(
        { ...values, organization, questions: [..._questions, ...importedQuestions] },
        { formOptions, modal }
      )
    );
  };

  return (
    <div>
      <Formik
        validationSchema={createQuestionnaireValidationSchema}
        initialValues={initialCreateQuestionnaireValues}
        onSubmit={(values, formikBag) => {
          handleCreateQuestionnaire(values, formikBag);
        }}
      >
        {({ setFieldValue, setValues, values }) => {
          let isPsychometric = values.model === PSYCHOMETRIC_TEST_QUESTIONNAIRE;
          console.log({ values });
          return (
            <Form>
              <Flex flexDirection="column" gap="1rem">
                <Box>
                  <FormInput placeholder="Name" label="Name" name="name" />
                </Box>
                <Box>
                  <QuestionnaireModelSelect
                    name="model"
                    onChange={val => handleQuestionnaireModelSelect(val, { setFieldValue, values, setValues })}
                  />
                </Box>
                <VisibilityWrapper flexDirection="column" isVisible={questionnaireTypeOptions?.length > 0}>
                  <Select
                    options={questionnaireTypeOptions}
                    onChange={value => handleQuestionnaireTypeSelectChange(value, { setValues, values })}
                    placeholder={`Select ${selectedModel} type`}
                    label={`${selectedModel} Type`.toLocaleUpperCase()}
                  />
                </VisibilityWrapper>

                {/* show multiselect for survery questionnaires */}
                <Flex flexDirection="column" gap="1rem">
                  <Box>
                    <CropCategorySelect
                      name="farm_operation_category"
                      label="Farm Categories"
                      onChange={value => handleFarmOperationCategory(value, { setFieldValue, values, setValues })}
                    />
                  </Box>
                  <Box>
                    <CropSelect
                      name="category_type"
                      label="Farm Type(s)"
                      placeholder="select a farm type"
                      categories={values.farm_operation_category}
                      getOptionValue={option => option.name}
                      onChange={value => handleCategoryType(value, { setFieldValue, values, setValues })}
                    />
                  </Box>
                </Flex>

                <VisibilityWrapper display="flex" flexDirection="column" isVisible={isPsychometric}>
                  {/* Cutt off mark */}
                  <Box>
                    <FormInput
                      placeholder="Cut Off Mark"
                      label="Cut off mark"
                      name="cut_off_mark"
                      type="number"
                      disabled={!isPsychometric}
                      min={0}
                      step={0.1}
                    />
                  </Box>
                  <Box>
                    <FormInput
                      placeholder="Score per Question"
                      label="Score per question"
                      name="score_per_question"
                      disabled={!isPsychometric}
                      type="number"
                      min="0"
                    />
                  </Box>
                </VisibilityWrapper>

                {/* Season */}
                <Box>
                  <Select
                    options={seasonOptions}
                    filterOptions={filterSeasonOptions}
                    label="Project"
                    placeholder="Select a Project"
                    name="season"
                    onChange={val => setFieldValue("season", val)}
                    ignoreGlobalState
                  />
                </Box>
                {/* Country */}
                <CountrySelect label="Country (optional)" onChange={val => setCountry(val)} />
                {/* Duplicate Questions  */}
                <VisibilityWrapper isVisible={values?.model} width="100%" display="block">
                  <Box>
                    <QuestionnaireSelect
                      onChange={handleImportQuestions}
                      model={values.model}
                      label="Import questions from previous questionaire"
                    />
                  </Box>
                </VisibilityWrapper>
                {/* Submit */}

                <Button
                  mt="2rem"
                  colorScheme="primary"
                  display="block"
                  disabled={loading}
                  type="submit"

                  // loading={loading}
                >
                  Create Questionnaire
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateQuestionnaireForm;
