import { getApiUrl } from "utils";
import { axiosService } from "./fetchService";
import { apiConstants } from "constants/apiConstants";

export default class ManageModule_API {
  static fetchAllManageModules = async __params => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.activationModules, path: "/getAll" });

    const { data } = await axiosService({ url, method: "GET" });
    return data;
  };

  static createModule = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.activationModules });
    return axiosService({ method: "POST", url, data: values });
  };

  static editModule = async values => {
    // if (!values?.id) throw new Error("Season is required");
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.activationModules });
    const { data } = await axiosService({ method: "PATCH", url, data: values?.data });
    return data;
  };
  static deleteModule = async id => {
    // if (!values?.id) throw new Error("Season is required");
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.activationModules });
    const { data } = await axiosService({ method: "DELETE", url, params: { id } });
    return data;
  };

  static getAllExceptGroups = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.activationModules, path: "/except-groups" });

    const { data } = await axiosService({ url, method: "GET", params });
    return data;
  };
}
