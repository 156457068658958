import { useDidMountEffect } from "hooks/ui/useDidMountEffect";
import { useState } from "react";
import { getAOSFlavour } from "services/customerService";

/**
 * React Custom hook to get the AOS flavour for the current app.
 * The AOS flavour is a string that represents the app variant.
 * It is used to determine the app's behaviour, such as the theme and the features that are available.
 *
 * @function useAOSFlavour
 * @return {Object} values an object that contains the AOS flavour.
 * @return {String} values.aosFlavour A string that represents the app variant.
 *
 * @example
 * const { aosFlavour } = useAOSFlavour();
 * if (aosFlavour === "aos") {
 *   // do something
 * }
 */
export function useAOSFlavour() {
  const [aosFlavour, setAosFlavour] = useState(getAOSFlavour());

  useDidMountEffect(() => {
    if (!aosFlavour) {
      setAosFlavour(() => getAOSFlavour()); // prevent null/empty string error by getting it aftermount as well
    }
  }, []);

  return { aosFlavour };
}
