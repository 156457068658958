import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";

import { GoogleMapsContext } from "@vis.gl/react-google-maps";

const defaultPolygonProps = {
  /**
   * The ordered sequence of coordinates that designates a closed loop. Unlike
   * polylines, a polygon may consist of one or more paths. As a result, the
   * paths property may specify one or more arrays of <code>LatLng</code>
   * coordinates. Paths are closed automatically; do not repeat the first
   * vertex of the path as the last vertex. Simple polygons may be defined
   * using a single array of <code>LatLng</code>s. More complex polygons may
   * specify an array of arrays. Any simple arrays are converted into <code><a
   * href="#MVCArray">MVCArray</a></code>s. Inserting or removing
   * <code>LatLng</code>s from the <code>MVCArray</code> will automatically
   * update the polygon on the map.
   */
  paths: [[]], //  google.maps.LatLng[][] Array of LatLng objects for the path
  clickable: null, //bool
  /**
   * If set to <code>true</code>, the user can drag this shape over the map.
   * The <code>geodesic</code> property defines the mode of dragging.
   * @defaultValue <code>false</code>
   */
  draggable: null, // bool
  /**
   * If set to <code>true</code>, the user can edit this shape by dragging the
   * control points shown at the vertices and on each segment.
   * @defaultValue <code>false</code>
   */
  editable: null,
  /**
   * The fill color. All CSS3 colors are supported except for extended named
   * colors.
   */
  fillColor: null, // string | null,
  /**
   * The fill opacity between 0.0 and 1.0
   */
  fillOpacity: null, // number | null,
  /**
   * When <code>true</code>, edges of the polygon are interpreted as geodesic
   * and will follow the curvature of the Earth. When <code>false</code>,
   * edges of the polygon are rendered as straight lines in screen space. Note
   * that the shape of a geodesic polygon may appear to change when dragged,
   * as the dimensions are maintained relative to the surface of the earth.
   * @defaultValue <code>false</code>
   */
  geodesic: null, // boolean | null,
  /**
   * Map on which to display Polygon.
   */
  map: null, // google.maps.Map | null,

  /**
   * The stroke color. All CSS3 colors are supported except for extended named
   * colors.
   */
  strokeColor: null, // string | null;
  /**
   * The stroke opacity between 0.0 and 1.0
   */
  strokeOpacity: null, // number | null;
  /**
   * The stroke position.
   * @defaultValue {@link google.maps.StrokePosition.CENTER}
   */
  strokePosition: null, // google.maps.StrokePosition | null;
  /**
   * The stroke width in pixels.
   */
  strokeWeight: null, // number | null;
  /**
   * Whether this polygon is visible on the map. boolean or true
   * @defaultValue <code>true</code>
   */
  visible: null, // boolean | null;
  /**
   * The zIndex compared to other polys. number or null
   */
  zIndex: null, // number | null;
  // event props
  onClick: __e => null,
  onDrag: __e => null,
  onDragStart: __e => null,
  onDragEnd: __e => null,
  onMouseOver: __e => null,
  onMouseOut: __e => null,
};

function usePolygon(props) {
  const mergedProps = { ...defaultPolygonProps, ...props };
  const { onClick, onDrag, onDragStart, onDragEnd, onMouseOver, onMouseOut, paths, ...polygonOptions } = mergedProps;

  // This is here to avoid triggering the useEffect below when the callbacks change (which happen if the user didn't memoize them)
  const callbacks = useRef({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
  });

  // const geometryLibrary = useMapsLibrary("geometry");

  const polygon = useRef(new window.google.maps.Polygon()).current;
  // update PolygonOptions (note the dependencies aren't properly checked
  // here, we just assume that setOptions is smart enough to not waste a
  // lot of time updating values that didn't change)
  useMemo(() => {
    polygon.setOptions(polygonOptions);
  }, [polygon, polygonOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  // update the path with the LatLng objects
  useMemo(() => {
    if (!paths) return;
    polygon.setPaths(paths);
  }, [polygon, paths]);

  // create polygon instance and add to the map once the map is available
  useEffect(() => {
    if (!map) {
      if (map === undefined) console.error("<Polygon> has to be inside a Map component.");

      return;
    }

    polygon.setMap(map);

    return () => {
      polygon.setMap(null);
    };
  }, [map]);

  // attach and re-attach event-handlers when any of the properties change
  useEffect(() => {
    if (!polygon) return;

    // Add event listeners
    const gme = window.google.maps.event;
    [
      ["click", "onClick"],
      ["drag", "onDrag"],
      ["dragstart", "onDragStart"],
      ["dragend", "onDragEnd"],
      ["mouseover", "onMouseOver"],
      ["mouseout", "onMouseOut"],
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(polygon, eventName, e => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    return () => {
      gme.clearInstanceListeners(polygon);
    };
  }, [polygon]);

  return polygon;
}

/**
 * Component to render a polygon on a map
 */

export const GoogleMapPolygon = forwardRef((props, ref) => {
  const polygon = usePolygon(props);

  useImperativeHandle(ref, () => polygon, []);

  return null;
});

GoogleMapPolygon.displayName = "GoogleMapPolygon";
