import { getRegionTerm } from "utils/flavour-utils";
import { useAOSFlavour } from "./useAosFlavour";
import { useMemo } from "react";

export function useFlavouredTerms() {
  const aosFlavour = useAOSFlavour();

  const regionTerm = useMemo(() => getRegionTerm(aosFlavour), [aosFlavour]);
  return {
    regionTerm,
  };
}
