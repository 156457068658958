import { getApiUrl } from "utils";
import { axiosService } from "./fetchService";
import { apiConstants } from "constants/apiConstants";

export default class InputDistribution_API {
  static fetchInputDistributionData = async (params, { signal }) => {
    const url = getApiUrl({
      endpointName: apiConstants.endpointNames.admin,
      path: `getAllFirstBatchDistributedInput`,
    });

    const response = await axiosService({ url, params, method: "GET", signal });
    console.log("response", response);
    return { data: response.data.data };
  };
}
