/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Badge, Text, badgeConstants } from "organic";
import { useTable } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { getRiskSurveyByIdData, getSurveyById } from "features/v1/riskManagement";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { getRegionTerm } from "utils/flavour-utils";
import { useAOSFlavour } from "hooks/app/multitenant/useAosFlavour";
import { useFlavouredTerms } from "hooks/app";

const useRiskFarmerTable = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const riskSurveyByIdData = useSelector(getRiskSurveyByIdData);

  const { regionTerm } = useFlavouredTerms();

  useEffect(() => {
    dispatch(getSurveyById(params.id));
  }, []);

  const columns = [
    {
      Header: "ID",
      accessor: "ta_number",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: cell => {
        const row = cell.row.original;
        return (
          <Text color="primary600" font="smMedium">
            {`${row?.first_name} ${row?.last_name}`}
          </Text>
        );
      },
    },
    {
      Header: "Last Updated",
      accessor: "updated_at",
      Cell: ({ value }) => <span>{format(new Date(value), "yyyy-MM-dd")}</span>,
    },
    {
      Header: regionTerm,
      accessor: "agent.region",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "State",
      accessor: "state",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "LGA",
      accessor: "lga",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Status",
      accessor: "validated",
      Cell: ({ value }) => {
        return (
          <>
            {value ? (
              <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>Validated</Badge>
            ) : (
              <Badge colorScheme={badgeConstants.colorSchemes.ERROR}>Flagged</Badge>
            )}
          </>
        );
      },
    },
  ];

  const paymentsTable = useTable({
    data: riskSurveyByIdData,
    columns,
    title: "All farmers",
    noDateRangeFilter: true,
    // noSearch: true,
    noViewAction: true,
    noEditAction: true,
    // Don't show any actions on payout tab
    noActions: false,
    // callbacks
  });
  return paymentsTable;
};

export default useRiskFarmerTable;
