import { apiConstants } from "constants/apiConstants";
import { AOSClientError } from "services/shared/AOSErrorService";
import { appGet } from "./object-utils";

export const resolveAdminApiBaseUrl = () => {
  let ADMIN_API = appGet(
    apiConstants.baseUrls,
    [apiConstants.currentPrefferedBackendServer, apiConstants.apiNames.ADMIN_API],
    apiConstants.defaults.ADMIN_API
  );

  return ADMIN_API;
};

export const resolveMainApiBaseUrl = () => {
  let MAIN_API = appGet(
    apiConstants.baseUrls,
    [apiConstants.currentPrefferedBackendServer, apiConstants.apiNames.MAIN_API],
    apiConstants.defaults.MAIN_API
  );

  return MAIN_API;
};

export const resolveV2ApiBaseUrl = () => {
  let V2_API = appGet(
    apiConstants.baseUrls,
    [apiConstants.currentPrefferedBackendServer, apiConstants.apiNames.V2_API],
    apiConstants.defaults.v2_API
  );

  return V2_API;
};
export const resolveWeatherApiBaseUrl = () => {
  let WEATHER_API = appGet(
    apiConstants.baseUrls,
    [apiConstants.apiNames.WEATHER_FORCAST_API],
    apiConstants.defaults.WEATHER_FORCAST_API
  );

  return WEATHER_API;
};

const resolveBackendServer = endpointName => {
  let serverId;
  if (apiConstants.currentPrefferedBackendServer === "v2") {
    return apiConstants.apiNames.V2_API;
  }

  Object.keys(apiConstants.endpointsByBackendServer)
    .filter(backend => backend !== apiConstants.apiNames.V2_API)
    .forEach(backendServer => {
      const foundEndpoint = apiConstants.endpointsByBackendServer[backendServer].find(
        endpoint => endpoint === endpointName
      );
      if (foundEndpoint) {
        // console.log(
        //   "found endpoint",
        //   foundEndpoint,
        //   "in",
        //   backendServer,
        //   "using",
        //   apiConstants.currentPrefferedBackendServer
        // );
        serverId = backendServer;
      }
    });
  if (!serverId) {
    throw new AOSClientError(
      `endpoint name not found attached to any api using preffered backend server ${apiConstants.currentPrefferedBackendServer},
          make sure endpoint name is added to apiConstants.endpointsByBackendServer object`
    );
  }

  return serverId;
};

function getApiBaseUrl(backendServer) {
  let baseURL;

  if (apiConstants.currentPrefferedBackendServer === "v2") {
    return resolveV2ApiBaseUrl();
  }

  // select baseURL based on bacekendServer arg
  switch (backendServer) {
    case apiConstants.apiNames.ADMIN_API:
      baseURL = resolveAdminApiBaseUrl();
      break;
    case apiConstants.apiNames.MAIN_API:
      baseURL = resolveMainApiBaseUrl();
      break;
    case apiConstants.apiNames.WEATHER_FORECAST_API:
      baseURL = resolveWeatherApiBaseUrl();
      break;
    default:
      baseURL = null;
  }

  if (!baseURL) {
    throw AOSClientError(`unable to get api base url for back-end server with id/apiName ${backendServer}`);
  }

  return baseURL;
}

/**
 * function takes an object as input and recursively generates a URL query string.
 * It properly handles nested objects by appending square brackets to the keys.
 * Arrays are also supported, and the resulting query string is properly URL-encoded.
 * @param {object} obj
 * @param {*} parentKey
 * @returns
 */
export function objectToQueryString(obj, parentKey = "") {
  const queryString = [];

  for (const key in obj) {
    if (obj?.prototype?.hasOwnProperty?.call?.(key)) {
      const value = obj[key];
      const encodedKey = parentKey ? `${parentKey}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

      if (Array.isArray(value)) {
        // Handle array values by iterating through each element
        value.forEach((element, index) => {
          const arrayKey = `${encodedKey}[${index}]`;
          queryString.push(`${arrayKey}=${encodeURIComponent(element)}`);
        });
      } else if (value && typeof value === "object") {
        // Recursively handle nested objects
        queryString.push(objectToQueryString(value, encodedKey));
      } else if (value !== undefined && value !== null) {
        // Encode key and value and add to the queryString array
        queryString.push(`${encodedKey}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryString.join("&");
}

/**
 * function uses a regular expression (keyValuePattern)
 * to check whether the provided string follows the basic format
 * of key-value pairs in a query string
 * @param {*} queryString
 * @returns {boolean} return true if the `queryString` is a valid URLSearchParams literal
 */
export function isValidQueryString(queryString) {
  // Regular expression for validating key-value pairs in a query string
  const keyValuePattern = /^[\w%]+=[\w%]*(&[\w%]+=[\w%]*)*$/;

  return keyValuePattern.test(queryString);
}

/** switch admin api endpoint based on NODE_ENV
 * if the different NODE_ENV based API env vars are avaliable
 */

/** switch users api endpoint based on NODE_ENV
 * if the different NODE_ENV based API env vars are avaliable
 */

export function getApiUrl({ endpointName = null, path = "", version = 1, queryParams = {} }) {
  if (!endpointName || !endpointName?.length) {
    throw new AOSClientError(`getApiUrl requires endpointName`);
  }

  if (endpointName && !apiConstants?.endpointNames?.[endpointName]) {
    throw new AOSClientError(
      `unknown/invalid endpointName psased to getApiUrl(),
      ${endpointName} does not exist in known endpoint names ${Object.keys(apiConstants.endpoints)}`
    );
  }

  const backendServer = resolveBackendServer(endpointName);

  const baseURL = getApiBaseUrl(backendServer);

  // Ensure that the base URL ends with a slash and the endpoint doesn't start with one
  const base = baseURL.endsWith("/") ? baseURL : baseURL + "/";

  // Combine the base URL and endpoint to form the final URL
  let finalURL = `${base}`;

  if (version) {
    finalURL = finalURL.concat(apiConstants.versions[version]);
  }

  if (endpointName && apiConstants.endpoints?.[`${endpointName}`]) {
    finalURL = finalURL.concat(apiConstants.endpoints[`${endpointName}`]);
  }

  // console.log({
  //   currentPrefferedBackendServer: apiConstants.currentPrefferedBackendServer,
  //   backendServer,
  //   baseURL,
  //   urlBeforePathAndQuery: finalURL,
  // });
  let urlPath = null;

  if (path && typeof path === "string") {
    urlPath = path.startsWith("/") ? path : "/" + path;
  }

  if (urlPath) {
    finalURL = finalURL.concat(urlPath);
  }

  const query = Object.keys(queryParams)?.length ? objectToQueryString(queryParams) : null;
  if (query) {
    finalURL = finalURL.concat(`?${query}`);
  }

  return finalURL;
}
