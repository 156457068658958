import { Table } from "components/table";
import { PageWrapper } from "components/layout";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSurveys,
  // getLoadingSurveyData,
  getSurveyData,
} from "features/v1/survey";
import { useTable, useDataFilter, useDisclosure } from "hooks";
import surveyApi from "api/survey";
import { Button, Flex, Icon, Image } from "organic";
import { ExportButton } from "components/export";
import { Modal, ModalContent } from "components/modal";
import { PageHeader } from "components/layout";
import ViewSurveyDetails from "./survey/viewSurveyDetails";
import { DropdownContent, DropdownTrigger, DropdownMenu, DropdownItem } from "components/dropdown";
import filterSvg from "assets/icons/filter.svg";
import { formatDate } from "utils";

const SurveysPage = () => {
  const [survey_type, setSurveyType] = useState();
  const dispatch = useDispatch();
  const surveysData = useSelector(getSurveyData);
  // const loadingSurveys = useSelector(getLoadingSurveyData);

  const _surveyTypeOptions = useMemo(() => {
    if (surveysData?.length && Array.isArray(surveysData)) {
      const _options = new Set();

      surveysData.forEach(surveyResponse => {
        _options.add(surveyResponse.survey_type);
      });
      return [..._options.values()];
    }
    return [];
  }, [surveysData]);

  const { filterParams, isValidFilter } = useDataFilter();

  const viewSurveyModal = useDisclosure();

  const { tableProps, selectedRow: selectedSurvey } = useTable({
    data: surveysData,
    columns: [
      {
        Header: "TA Number",
        accessor: "ta_number",
      },
      {
        Header: "Survey type",
        accessor: "survey_type",
      },
      {
        Header: "Farmer Serial ID",
        accessor: "farmer_id",
      },
      // {
      //   id: "surveyQuestion",
      //   Header: "Question",
      //   accessor: survey => {return get(survey, ["feedback", 0, "question"], "N/A")}
      // },
      // {
      //   id: "surveyResponse",
      //   Header: "Response",
      //   accessor: survey => {return get(survey, ["feedback", 0, "answer"], "N/A")}
      // },

      {
        id: "surveryCreatedDate",
        Header: "Created on",
        accessor: survey => formatDate(survey.created_at),
      },
    ],
    title: "All Surveys",
    noEditAction: true,
  });
  // api calls

  const advanceSurveyExport = async ({ filterParams, columnWhitelist }) => {
    let params = { columnWhitelist, ...filterParams, survey_type: survey_type ?? undefined };
    const res = await surveyApi.get("advance", { params });
    return res;
  };

  const quickSurveysExport = async () => {
    const data = (
      await surveyApi.get("downloadSurveys", {
        params: { ...filterParams, survey_type: survey_type ?? undefined },
      })
    ).data;
    return data;
  };

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" align-Items="center">
        <PageHeader title="Surveys" />
        <ExportButton
          quickExportConfig={{
            apiCall: quickSurveysExport,
            fileName: `AOSSurveysQuickExport${formatDate(new Date())}.csv`,
          }}
          advancedExportConfig={{
            apiCall: advanceSurveyExport,
          }}
        />
      </Flex>
      <Table
        localFilterComponent={
          <DropdownMenu>
            <DropdownTrigger asChild>
              <Button colorScheme="secondaryGray">
                <>
                  <Image src={filterSvg} alt="filter icon" size="20px" mr="0.5rem" />
                  {survey_type ? survey_type : "Filter Surveys"}
                </>
              </Button>
            </DropdownTrigger>
            <DropdownContent unstyled>
              <DropdownItem
                onSelect={() => {
                  setSurveyType(undefined);
                  dispatch(
                    getAllSurveys({
                      seasonId: filterParams.seasonId,
                      dateRange: filterParams.dateRange,
                    })
                  );
                }}
              >
                <Flex alignItems="center" gap="0.5rem">
                  <Icon icon="x" /> Clear
                </Flex>
              </DropdownItem>
              {_surveyTypeOptions.map(surveyType => (
                <DropdownItem
                  key={surveyType}
                  onSelect={() => {
                    setSurveyType(surveyType);
                    if (isValidFilter) {
                      dispatch(
                        getAllSurveys({
                          seasonId: filterParams.seasonId,
                          dateRange: filterParams.dateRange,
                          survey_type: surveyType,
                        })
                      );
                    }
                  }}
                >
                  {surveyType}
                </DropdownItem>
              ))}
            </DropdownContent>
          </DropdownMenu>
        }
        {...tableProps}
        onDataFilter={({ seasonId, dateRange }) => {
          dispatch(getAllSurveys({ seasonId, dateRange }));
        }}
        onViewAction={() => {
          viewSurveyModal.onOpen();
        }}
      />
      <Modal {...viewSurveyModal.modalProps}>
        <ModalContent title="Survey Details">
          <ViewSurveyDetails modal={viewSurveyModal} selected={selectedSurvey} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export default SurveysPage;
