import React from "react";
import axios from "axios";
import NProgress from "utils/v1/NProgress";
import agents from "api/agents";
import farmers from "api/farmers";
import farmMonitoring from "api/farmMonitoring";
import farmSupervision from "api/farmSupervision";
import language from "api/language";
import subscription from "api/subscription";
import warehouse from "api/warehouse";
import commitmentFees from "api/commitmentFees";
import harvest from "api/harvest";
import contractors from "api/contractors";
import crops from "api/crops";
import inputs from "api/inputs";
import region from "api/region";
import zone from "api/zone";
import questionnaire from "api/questionnaire";
import farmSupervisionCategory from "api/farmSupervisionCategory";
import role from "api/role";
import survey from "api/survey";
import farmerSeason from "api/farmerseason";
import farmerPayment from "api/farmerPayment";
import bulkSms from "api/bulkSms";

export default function ProgressLoader() {
  const calculatePercentage = (loaded, total) => Math.floor(loaded * 1.0) / total;

  function loadProgressBar(config, instance = axios) {
    let requestsCounter = 0;

    const setupStartProgress = () => {
      instance.interceptors.request.use(config => {
        requestsCounter++;
        NProgress.start();
        return config;
      });
    };

    const setupUpdateProgress = () => {
      const update = e => NProgress.inc(calculatePercentage(e.loaded, e.total));
      instance.defaults.onDownloadProgress = update;
      instance.defaults.onUploadProgress = update;
    };

    const setupStopProgress = () => {
      const responseFunc = response => {
        if (--requestsCounter === 0) {
          NProgress.done();
        }
        return response;
      };

      const errorFunc = error => {
        if (--requestsCounter === 0) {
          NProgress.done();
        }
        return Promise.reject(error);
      };

      instance.interceptors.response.use(responseFunc, errorFunc);
    };

    NProgress.configure(config);
    setupStartProgress();
    setupUpdateProgress();
    setupStopProgress();
  }

  const axiosInstancesToMonitor = [
    agents,
    commitmentFees,
    farmers,
    farmMonitoring,
    farmSupervision,
    language,
    subscription,
    warehouse,
    harvest,
    contractors,
    crops,
    inputs,
    region,
    zone,
    questionnaire,
    farmSupervisionCategory,
    role,
    survey,
    farmerSeason,
    farmerPayment,
    bulkSms,
  ];
  axiosInstancesToMonitor.forEach((instance, __i) => loadProgressBar({}, instance));

  return (
    // display is handled by NProgress script
    <span />
  );
}

// import 'nprogress/nprogress.css'
