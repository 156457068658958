import axios from "axios";
import { getCurrentAosVersion, getCustomerID } from "services/customerService";
import authService from "../services/v1/authService";

import { getApiUrl } from "utils";
import { apiConstants } from "constants/apiConstants";

let API = getApiUrl({ endpointName: apiConstants.endpointNames.farmers });
// let API = baseApi + "/api/v1/resource/farmers";

const customerid = getCustomerID();
const aosVersion = getCurrentAosVersion();

const farmers = axios.create({
  baseURL: API,
  headers: { "x-aos-customerid": customerid, "x-aos-version": aosVersion },
});

farmers.defaults.headers.post["Content-Type"] = "application/json";
farmers.defaults.headers["Access-Control-Allow-Origin"] = "*";

farmers.interceptors.request.use(
  config => {
    const token = authService.getToken();
    const organization = authService.getOrg();
    config.params = config?.params || {};

    if (organization && !config?.ignoreOrgId) {
      config.params["organizationId"] = organization;
    }

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    authService.tokenExpired(err);
    return Promise.reject(err);
  }
);

farmers.interceptors.response.use(
  config => config,
  err => {
    authService.tokenExpired(err);
    return Promise.reject(err);
  }
);

export default farmers;
