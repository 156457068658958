import { EmptyState } from "components/appState";
import Card from "components/card/card";
import ChartGrid from "components/chart/chartGrid";
import { PageHeader, PageWrapper } from "components/layout";
import { Switch } from "components/switch";
import { getUserData } from "features/v1/auth";
import { useAppRoles } from "hooks/app";
import { Box, Button, Flex, Text, buttonConstants } from "organic";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { queryStringToObject } from "utils";
import AverageFieldSize from "views/charts/averageFieldSize";
import AverageGroupSize from "views/charts/averageGroupsSize";
import NoOfSubscriptions from "views/charts/noOfSubscriptions";
import TotalFarmers from "views/charts/totalFarmers";
import TotalFieldsMapped from "views/charts/totalFieldsMapped";
import TotalGroups from "views/charts/totalGroups";

const OVERVIEW_GRID_LEFT_ROW_HEIGHT = 249;
// const OVERVIEW_GRID_RIGHT_GRID_HEIGHT = 207;

const DEMO_MODE_KEY = "trackingId";

const OVERVIEW_GRID_LEFT_GRID_NAME = "overviewLeftGrid";
// const OVERVIEW_GRID_RIGHT_GRID_NAME = "overviewRightGrid";

const LEFT_COL_COUNT = 3;
// const RIGHT_COL_COUNT = 1;

const godModeProtocols = () => {
  activateDemoMode();
  location.pathname = "/";
};

const abort = () => {
  deactivateDemoMode();
  location.pathname = "/";
};

const goHome = () => {
  location.pathname = "";
};
const activateDemoMode = () => {
  sessionStorage?.setItem?.(DEMO_MODE_KEY, 6969);
};
const deactivateDemoMode = () => {
  sessionStorage?.removeItem?.(DEMO_MODE_KEY, 6969);
};

const GGPage = () => {
  const [searchParams, __] = useSearchParams();
  const [isDemoMode, setIsDemoMode] = useState(sessionStorage?.getItem?.(DEMO_MODE_KEY) ? true : false);

  let { first_name } = useSelector(getUserData);
  const userRole = useAppRoles();

  const handleCreateError = () => {
    // eslint-disable-next-line no-undef
    methodDoesNotExist();
    throw new Error("Test error");
  };

  useEffect(() => {
    const options = queryStringToObject(searchParams);
    if (options?.permit_guest) {
      `${options.permit_guest}` === (69).toString(16) ? godModeProtocols() : abort();
    } else {
      !userRole.isRole("superAdmin") && goHome();
    }

    if (options?.revoke_access) {
      options.revoke_access === (6969).toString(16) ? deactivateDemoMode() : goHome();
    }
  }, [searchParams, userRole]);

  return (
    <PageWrapper>
      <PageHeader
        title={`Welcome ${first_name ? ", " + first_name : ""} to GGPage 😇 > Experimental Features`}
        subtitle="a hidden page that is restricted to super admin"
      />
      {userRole.canRead(["all"]) ? (
        <>
          <Flex flexDirection="column" gap="2rem">
            <Card>
              <Text font="displaySmMedium" color="gray500" mb="2rem">
                Test Error Reporting
              </Text>
              <Card width="50%" bg="gray300">
                <Text font="smMedium" color="gray700" mt="0.5rem" mb="1rem">
                  click me multiple times 👇
                </Text>
                <div>
                  <Button variant={buttonConstants.variants.DESTRUCTIVE} onClick={handleCreateError}>
                    Break Everything
                  </Button>
                </div>
              </Card>
            </Card>
            <Card>
              <Text font="displaySmMedium" color="gray500" mb="2rem">
                God Mode Privileges
              </Text>
              <Card width="50%" title={"Demo mode"} bg="gray300">
                <Text font="lgMedium" color="gray700" mt="0.5rem" mb="1rem">
                  activate mock overview👇
                </Text>
                <div>
                  <Switch
                    size="md"
                    colorScheme="light"
                    checked={isDemoMode}
                    onCheckedChange={e => {
                      setIsDemoMode(e);
                      if (isDemoMode === true) {
                        activateDemoMode();
                      } else {
                        deactivateDemoMode();
                      }
                    }}
                  />
                </div>
              </Card>
            </Card>
          </Flex>
          <Box mt={2}>
            <ChartGrid
              gridData={leftGridSchema}
              rowHeight={OVERVIEW_GRID_LEFT_ROW_HEIGHT}
              cols={LEFT_COL_COUNT}
              gridGap={16}
              gridName={OVERVIEW_GRID_LEFT_GRID_NAME}
            />
          </Box>
        </>
      ) : (
        <EmptyState
          title="Nothing to see here"
          subtitle="The page you're looking for doesn't exist"
          message="but not to worry we're sending you back to a safe point"
        />
      )}
    </PageWrapper>
  );
};

export default GGPage;

export const TOTAL_GROUPS_BAR_KEY = "totalGroupsBar";
export const TOTAL_GROUPS_AREA_KEY = "totalGroupsKey";
export const TOTAL_FARMERS_KEY = "totalFarmers";
export const AVERAGE_GROUP_SIZE_KEY = "averageGroupSize";
export const AVERAGE_FIELD_SIZE_KEY = "averageFieldSize";
export const TOTAL_FIELDS_MAPPED_KEY = "totalFieldsMapped";
export const NO_OF_FIELDS_MAPPED_KEY = "noOfFieldsMapped";
export const NO_OF_SUBSCRIPTIONS_KEY = "noOfSubscriptions";
export const TOTAL_AGENTS_KEY = "totalAgents";

const leftGridSchema = [
  {
    key: TOTAL_GROUPS_BAR_KEY,
    title: "Total Groups",
    subtitle: "",
    type: "bar",
    height: 122,
    width: "100%",
    component: TotalGroups,
  },
  {
    key: TOTAL_GROUPS_AREA_KEY,
    title: "Total Groups",
    subtitle: "",
    type: "area",
    height: 122,
    width: "100%",
    component: TotalGroups,
  },
  {
    key: TOTAL_FARMERS_KEY,
    title: "Total Farmers",
    subtitle: "Farmers",
    type: "area",
    height: 122,
    width: "100%",
    component: TotalFarmers,
  },
  {
    key: AVERAGE_GROUP_SIZE_KEY,
    title: "Average Group Size",
    subtitle: "People",
    type: "area",
    height: 122,
    width: "100%",
    component: AverageGroupSize,
  },
  {
    key: AVERAGE_FIELD_SIZE_KEY,
    title: "Average Field Size",
    subtitle: "Hectares",
    type: "area",
    height: 122,
    width: "100%",
    component: AverageFieldSize,
  },

  {
    key: TOTAL_FIELDS_MAPPED_KEY,
    title: "Total Fields Mapped",
    subtitle: "Hectares",
    type: "area",
    height: 122,
    width: "100%",
    component: TotalFieldsMapped,
  },
  // {
  //   key: NO_OF_FIELDS_MAPPED_KEY,
  //   title: "No. of Fields Mapped",
  //   subtitle: "Feilds",
  //   type: "area",
  //   height: 122,
  //   width: "100%",
  //   component: NoOfFieldsMapped,
  // },
  {
    key: NO_OF_SUBSCRIPTIONS_KEY,
    title: "No of Subscriptions",
    subtitle: "Subscriptions",
    type: "area",
    height: 122,
    width: "100%",
    component: NoOfSubscriptions,
  },

  // {
  //   key: TOTAL_AGENTS_KEY,
  //   title: "Total Agents",
  //   subtitle: "Agents",
  //   type: "area",
  //   height: 122,
  //   width: "100%",
  //   component: TotalAgents,
  // },
];
