import * as React from "react";
import { EmptyState } from "components/appState";
import { organicTheme } from "organic";
// import { googleMapStyles } from "hooks/app";

export const DEFAULT_MAP_MAX_ZOOM_LEVEL = 15;
export const DEFAULT_POSITION = { lat: 9.056453186100377, lng: 7.4618331253553825 };

export const defaultMapOptions = {
  center: DEFAULT_POSITION, // wink wink
  zoom: DEFAULT_MAP_MAX_ZOOM_LEVEL,
  // styles: mapStyles,
};

export const googleMapConstants = {
  defaults: {
    POSTION: DEFAULT_POSITION,
    ZOOM: 15,
    MAP_OPTIONS: {
      // center: DEFAULT_POSITION, // wink wink
      // zoom: 15,
      defaultCenter: DEFAULT_POSITION, // wink wink
      defaultZoom: 15,
      disableDefaultUI: false,
      gestureHandleing: "greedy",
      // styles: googleMapStyles,
    },
    MARKER: {
      position: DEFAULT_POSITION,
    },
    MARKER_ICON: "/farm-marker.svg",
    POLYGON_STYLE: {
      fillColor: organicTheme.colors.primary300,
      strokeColor: organicTheme.colors.primary800,
      strokeOpacity: 1,
      strokeWeight: 3,
    },
    REDUCER_STATE: {
      gmap: {},
      isLoaded: false,
      currentCenter: { lat: 35.82, lng: 76.5 },
      currentCountry: "",
      currentState: "",
      markers: [],
      polygons: [],
      isLoadingGeocode: false,
      geocodeResults: [],
      isLoadingReverseGeocode: false,
      reverseGeocodeResults: [],
    },
  },
  markerIcons: {
    farm: "/farm-marker.svg",
  },
};

export const leafletMapConstants = {
  defaults: {
    POSTION: DEFAULT_POSITION,
    ZOOM: 15,
    MAP_OPTIONS: {
      center: DEFAULT_POSITION, // wink wink
      zoom: 15,
      placeholder: <EmptyState title="Loading Map...." subtitle="this will take a few seconds" />,
      scrollWheelZoom: false,
    },
    MARKER: {
      position: DEFAULT_POSITION,
    },
    MARKER_ICON: "/farm-marker.svg",
    POLYGON_STYLE: {
      fillColor: organicTheme.colors.primary300,
      strokeColor: organicTheme.colors.primary800,
      color: organicTheme.colors.primary800,
      strokeOpacity: 1,
      strokeWeight: 3,
    },
    OVERLAPED_POLYGON_STYLE: {
      fillColor: organicTheme.colors.error300,
      strokeColor: organicTheme.colors.error800,
      color: organicTheme.colors.error800,
      strokeOpacity: 1,
      strokeWeight: 3,
    },
    REDUCER_STATE: {
      isLoaded: false,
      lmap: {},
      currentCenter: DEFAULT_POSITION,
      markers: [],
      polygons: [],
      isInitialised: false,
    },
  },
};
