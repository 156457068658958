import React, { useEffect, useState } from "react";

import {
  getAllFarmersWithParamsOverview,
  getAveragefarmersPerAgent,
  getLoadingFarmers,
  getTotalFarmersCount,
} from "features/v1/farmers";
import { getSelectedSeason } from "features/v1/season";
import { useTable, useDisclosure } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import agentsApi from "api/agents";
// import season from "api/season";
import OverlayLoader from "components/v1/Loader/OverlayLoader";
import { PageWrapper } from "components/layout";
import {
  getAgentChartData,
  getAgentData,
  getAgentMeta,
  getAllAgentsWithParams,
  getLoadingAgents,
  getLoadingSearchAgents,
  getTotalAgentCount,
  searchAllAgentsWithParams,
} from "features/v1/agents";
import Chart from "components/chart/chart";
import Card from "components/card/card";
import ChartHeadersList from "components/chart/chartHeadersList";
import { Flex } from "organic";
import { getPercentageOf, uniqueId } from "utils";
import { Table } from "components/table";
import { ExportButton } from "components/export";
import { Modal, ModalContent } from "components/modal";
import EditAgentDetails from "./agent/editAgentDetails";
import ViewAgentsFarmers from "./agent/viewAgentsFarmers";
import { PageHeader } from "components/layout";
import { getRegionTerm } from "utils/flavour-utils";
// dayjs.extend(customParseFormat);

const AGENTS_FARMERS_ACTION_KEY = "agentsFarmers";

const AgentsPage = () => {
  const [target, setTarget] = useState(0);
  const [achieved, setAchieved] = useState(0);

  const dispatch = useDispatch();

  // redux selectors
  let selectedSeason = useSelector(getSelectedSeason);
  let farmerLoading = useSelector(getLoadingFarmers);
  let totalFarmersCount = useSelector(getTotalFarmersCount);

  let ignoredloading = useSelector(getLoadingAgents);
  let agentData = useSelector(getAgentData);
  let agentChartData = useSelector(getAgentChartData);
  let totalAgentCount = useSelector(getTotalAgentCount);
  let averagefarmersPerAgent = useSelector(getAveragefarmersPerAgent);
  let agentMeta = useSelector(getAgentMeta);
  let ignoredloadingSearch = useSelector(getLoadingSearchAgents);

  const { tableProps, selectedRow: selectedAgent } = useTable({
    columns: [
      {
        id: "agentId",
        Header: "ID",
        accessor: "phone_number",
      },
      {
        idFunction: uniqueId(),
        id: "agentFullName",
        Header: "Name",
        accessor: agent => `${agent?.first_name} ${agent?.last_name}`,
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "Secondary Phone No.",
        accessor: "phone_number_2",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "LGA",
        accessor: "lga",
      },
      {
        Header: getRegionTerm(),
        accessor: "region",
      },
      {
        Header: "Zone",
        accessor: "zone",
      },
    ],
    data: agentData,
    title: "All Agents",
    useServerPagination: true,
    serverPaginationMeta: agentMeta,
    noViewAction: true,
    showCheckboxes: true,
  });

  const quickAgentsExport = async () => {
    const data = (await agentsApi.get("download", { params: tableProps.filterParams })).data;
    return data;
  };
  const advancedAgentsExport = async ({ filterParams, columnWhitelist }) => {
    let params = { ...filterParams, columnWhitelist };
    const res = await agentsApi.get("download/advance", { params });
    return res;
  };
  const getAgentsExportKeys = async () => {
    return await agentsApi.get("download/getMetaKeys");
  };

  useEffect(() => {
    setTarget(selectedSeason?.target);
    setAchieved(totalFarmersCount);
  }, [selectedSeason, totalFarmersCount]);

  const agentEditModal = useDisclosure();
  const agentsFarmersModal = useDisclosure();

  return (
    <PageWrapper>
      <Flex flexDirection="row" width="100%" justifyContent="space-between" align-Items="center">
        <PageHeader title="Agents" subtitle="Your farmers summary and activity." />
        <ExportButton
          quickExportConfig={{
            apiCall: quickAgentsExport,
          }}
          advancedExportConfig={{
            apiCall: advancedAgentsExport,
            getKeys: getAgentsExportKeys,
          }}
        />
      </Flex>
      <Flex flexDirection={["column", "_", "_", "_", "row"]} gap="1rem" width="100%">
        <Card height={382} width="100%">
          <ChartHeadersList
            headers={[
              { title: "All Agents", value: totalAgentCount },
              { title: "Average Farmer Per Agent", value: averagefarmersPerAgent },
            ]}
          />
          <Chart type="area" data={agentChartData} height={242} yAxisLabel={"agents"} />
        </Card>
        <Card height={382} width={"100%"}>
          <OverlayLoader active={farmerLoading}>
            <ChartHeadersList
              headers={[
                { title: "Target", value: target },
                { title: "Acheived", value: achieved },
              ]}
            />
            <Chart
              type="progress"
              size={240}
              data={{
                name: "Target Percentage",
                progress: getPercentageOf({ target, achieved }),
              }}
              trackWidth={24}
              indicatorWidth={24}
            />
          </OverlayLoader>
        </Card>
      </Flex>
      <Table
        {...tableProps}
        getExtraActions={() => [
          {
            label: "View Farmers Onboarded",
            icon: "users",
            actionKey: AGENTS_FARMERS_ACTION_KEY,
          },
        ]}
        onDataFilter={params => {
          dispatch(getAllAgentsWithParams(params));
          dispatch(getAllFarmersWithParamsOverview(params));
        }}
        onEditAction={() => {
          agentEditModal.onOpen();
        }}
        onSearch={(params, { abortController }) => {
          dispatch(searchAllAgentsWithParams(params, { abortController }));
        }}
        onExtraAction={(selectedAction, { __params }) => {
          if (selectedAction.actionKey === AGENTS_FARMERS_ACTION_KEY) {
            agentsFarmersModal.onOpen();
          }
        }}
      />
      <Modal
        open={agentEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            agentEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Agent Details">
          <EditAgentDetails selected={selectedAgent} modal={agentEditModal.modal} />
        </ModalContent>
      </Modal>
      {/* view farmers onboarded */}
      <Modal {...agentsFarmersModal.modalProps}>
        <ModalContent title="Farmers Onboarded" size="md">
          <ViewAgentsFarmers selected={selectedAgent} modal={agentEditModal.modal} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export default AgentsPage;
